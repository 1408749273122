@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf') format('woff'); /* or 'woff' */
}

html { 
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto-Regular';
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb { 
  background: rgb(301,127,51);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(271,97,21);
}